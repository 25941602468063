import axios from 'axios';
import React from 'react';
import { Alert } from 'react-bootstrap';
import ReactDOM from 'react-dom';

const ROOT_DOMAIN = 'test.4dorian.net';
const LIVE_ROOT_DOMAIN = 'live.4dorian.net';
const AUX_LIVE_DOMAINS = ['dorian.live'];
const DEFAULT_PORPOISE_NAMESPACE = 'dev';

const LIVE_DOMAINS = [LIVE_ROOT_DOMAIN, ...AUX_LIVE_DOMAINS];

const PORPOISE_NAMESPACE_MATCHER = new RegExp(`^[\\w]+\\.([\\w]+[-]?[\\w]+)\\.${ROOT_DOMAIN.replace(/\./g, '\\.')}$`);

const porpoisePath = (sub, namespace) => `https://${sub}.${namespace}.${ROOT_DOMAIN}`;
const porpoiseProductionPath = (sub) => `https://${sub}.${LIVE_ROOT_DOMAIN}`;

const getPorpoiseSubdomain = (sub, _hostname) => {
  const host = _hostname || window?.location?.hostname || '';

  if (LIVE_DOMAINS.some((liveDomain) => String(host).endsWith(liveDomain))) {
    return porpoiseProductionPath(sub);
  }

  const namespace = PORPOISE_NAMESPACE_MATCHER
    .exec(host)?.[1] || DEFAULT_PORPOISE_NAMESPACE;
  return porpoisePath(sub, namespace);
};

const url = `${getPorpoiseSubdomain('engine')}/`;

export const baseURL = url;

if (!window?.location?.hostname.endsWith('localhost')) {
  axios.defaults.withCredentials = true;
}

export const userApi = axios.create({
  baseURL: `${getPorpoiseSubdomain('user')}/`,
});

export const environmentApi = axios.create({
  baseURL: `${getPorpoiseSubdomain('env')}/`,
});

export const api = axios.create({
  baseURL,
});

const token = localStorage.getItem('token');
if (token) {
  api.defaults.headers.common.Authorization = token;
}

api.defaults.headers.post['Content-Type'] = 'application/json';

api.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    if (error.response.config.url.replace(error.response.config.baseURL, '') !== 'v1/login') {
      window.location.assign('/logout');
    }
  }

  if (error.response) {
    let errorText = error.response.statusText;

    if (error.response.data) {
      if (error.response.data.error) {
        errorText = error.response.data.error;
      } else if (error.response.data.message) {
        errorText = error.response.data.message;
      }
    }

    const isDocumentsNeedSigningError = error.response.data?.documents;
    const isTeapotError = String(error.response.status) === '418';
    const isErrorHandled = isDocumentsNeedSigningError || isTeapotError;
    if (!isErrorHandled) {
      // TODO: https://dorian.atlassian.net/browse/DOR-3171
      ReactDOM.render(
        <Alert variant="danger" className="mx-3 my-3">
          {errorText}
        </Alert>,
        document.getElementById('errorsBox'),
      );
    }
  }

  setTimeout(() => {
    ReactDOM.render(null, document.getElementById('errorsBox'));
  }, 3000);

  return Promise.reject(error);
});
