import React, { Suspense } from 'react';

const isProduction = window.location.hostname.endsWith('dorian.live');

function LandingPageRedirect() {
  const tab = window.location.hash;
  const path = window.location.pathname;
  const redirectHost = isProduction
    ? 'editor.live.4dorian.net'
    : 'editor.dev.test.4dorian.net';

  window.location = `https://${redirectHost}${path}${tab}`;

  return null;
}

window.$traits = process.env.REACT_APP_TRAITS && process.env.REACT_APP_TRAITS === 'true';

export function Routes() {
  return (
    <Suspense fallback={<div />}>
      <LandingPageRedirect />
    </Suspense>
  );
}
