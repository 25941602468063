import { getLogLevelByEnv } from './getLogLevelByEnv';

export enum LoggerLevel {
  Info = 0,
  Log = 1,
  Debug = 2,
  Error = 3,
  Silent = 99,
}

class Logger {
  private level: LoggerLevel;

  constructor(level: LoggerLevel) {
    this.level = level;
  }

  info(message?: unknown, ...optionalParams: unknown[]) {
    if (this.level <= LoggerLevel.Info) {
      // eslint-disable-next-line no-console
      console.info(message, ...optionalParams);
    }
  }

  log(message?: unknown, ...optionalParams: unknown[]) {
    if (this.level <= LoggerLevel.Log) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  }

  debug(message?: unknown, ...optionalParams: unknown[]) {
    if (this.level <= LoggerLevel.Debug) {
      // eslint-disable-next-line no-console
      console.debug(message, ...optionalParams);
    }
  }

  error(message?: unknown, ...optionalParams: unknown[]) {
    if (this.level <= LoggerLevel.Error) {
      // eslint-disable-next-line no-console
      console.error(message, ...optionalParams);
    }
  }

  setLevel(level: LoggerLevel) {
    this.level = level;
  }
}

const logger = new Logger(getLogLevelByEnv());

export { logger };
