import { BugTrackerType, createFrontendBugTracker } from '@dorian/frontend-bug-tracker';
import axios from 'axios';
import { isEqual } from 'lodash-es';
import { getRuntimeEnv } from '../../helpers/runtimeEnv';
import { logger } from '../loggerService/loggerService';
import { BugTrackerConfig } from './types/bugTracker';

class BugTrackerService {
  private bugTrackerService;

  private bugTrackerConfig: BugTrackerConfig;

  constructor() {
    this.bugTrackerService = createFrontendBugTracker();
    this.bugTrackerConfig = {
      bugTrackerType: BugTrackerType.Sentry,
      tracesSampleRate: 0.2,
      sampleRate: 0.2,
    };
    this.fetchBugTrackerParams().then((config) => this.initBugTrackerWithConfig(config));
    logger.info('[AT] Instantiate bug tracker service');
  }

  public getBugTrackerService() {
    return this.bugTrackerService;
  }

  public async getBugTrackerConfig() {
    const config = await this.fetchBugTrackerParams();
    return config;
  }

  public changeBugTracker(type: BugTrackerType) {
    this.bugTrackerService = createFrontendBugTracker(type);
    logger.info('[AT] Change bug tracker service to ', type);
  }

  private initBugTrackerWithConfig(config: BugTrackerConfig | undefined) {
    const release = process.env.REACT_APP_RELEASE ?? '';
    const environment = getRuntimeEnv();

    if (isEqual(config, this.bugTrackerConfig)) {
      return;
    }
    if (config?.bugTrackerType) {
      const { bugTrackerType, sampleRate, tracesSampleRate } = config;
      this.changeBugTracker(bugTrackerType);
      const oldBugTrackerConfig = this.bugTrackerService.getConfig();
      const newBugTrackerConfig = {
        ...oldBugTrackerConfig,
        init: {
          environment,
          sampleRate,
          tracesSampleRate,
        },
      };
      this.bugTrackerService.init(release, newBugTrackerConfig);
      this.bugTrackerConfig = config;
      logger.info('[AT] Init bug tracker service');
    }
  }

  private async fetchBugTrackerParams() {
    const env = `${getRuntimeEnv()}`.toLowerCase();
    try {
      const response = await axios.get(`https://env.dev.test.4dorian.net/api/settings/?client=editor&env=${env}`);
      const { bugTracker } = response.data.data.config;
      const bugTrackerConfig: BugTrackerConfig = {
        ...this.bugTrackerConfig,
        ...bugTracker,
      };
      logger.info('[AT] Fetch remote bug tracker config');
      return bugTrackerConfig as BugTrackerConfig;
    } catch (err) {
      logger.error('Error parsing bug tracker config.', err);
    }
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async updateBugTrackerConfig(config: BugTrackerConfig) {
    throw Error('Not implemented');
  }
}

const bugTrackerInstance = new BugTrackerService();

const bugTracker = () => {
  if (bugTrackerInstance === undefined) {
    throw new Error('Instance not instantiated');
  }
  return bugTrackerInstance.getBugTrackerService();
};

export { bugTracker, bugTrackerInstance };
