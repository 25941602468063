import React from 'react';
import { Offline } from 'react-detect-offline';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import of general styles should come before any
// app related code to keep CSS priority of general styles low
// eslint-disable-next-line import/order
import './style.scss';
// eslint-disable-next-line import/order
import './index.scss';
import { baseURL } from './components/api';
import { Auth } from './components/Auth/Auth';
import { Routes } from './components/router';
import { bugTracker } from './services/bugTracker/BugTrackerService';
import * as serviceWorker from './serviceWorker';
import { VersionCheck } from './Version';

bugTracker().init(process.env.REACT_APP_RELEASE ?? '');

const auth = new Auth();
const polling = {
  enabled: false,
  interval: 5000,
  timeout: 7000,
  url: `${baseURL}v1/`,
};

ReactDOM.render(
  <BrowserRouter>
    <VersionCheck />
    <Routes auth={auth} />
    <Offline polling={polling}>
      <div className="appOfflineBox">
        <div className="appOfflineBoxContent">
          App Offline
        </div>
      </div>
    </Offline>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
