import * as React from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const hasLatest = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    if (a !== b) return a > b || Number.isNaN(b);
  }
  return false;
};

export class VersionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldRefresh = hasLatest(latestVersion, currentVersion);

        if (shouldRefresh) {
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion } = this.state;
    if (!loading && !isLatestVersion) {
      // eslint-disable-next-line no-console
      console.log('Please reload page');
    }
    return null;
  }
}
