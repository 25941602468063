export class Auth {
  constructor() {
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getUser = this.getUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  isAuthenticated() {
    return localStorage.getItem('isToken');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  login(response) {
    const createTemplate = localStorage.getItem('createTemplate');
    const deviceId = localStorage.getItem('deviceId');

    localStorage.clear();
    localStorage.setItem('deviceId', deviceId);
    localStorage.setItem('isToken', response.success);
    localStorage.setItem('token', response.token);
    localStorage.setItem('documents', JSON.stringify(response.documents));
    localStorage.setItem('user', JSON.stringify(response.user));
    localStorage.setItem('createTemplate', createTemplate);

    if (sessionStorage.getItem('delayRegistration') && createTemplate) {
      sessionStorage.clear();
      return window.location.replace(`/create/template/${JSON.parse(createTemplate).id}`);
    }
    sessionStorage.clear();
    window.location.reload();
    return null;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
}
