import { RuntimeEnv } from './RuntimeEnvEnum';

const prodHostnames = [
  'dorianplatform.com',
  'dorianlive.tv',
  'dorian.watch',
  'dorian.live',
];

export const getRuntimeEnv = (): RuntimeEnv => {
  const { hostname } = window.location;
  if (prodHostnames.some((prodHostname) => hostname.endsWith(prodHostname))) {
    return RuntimeEnv.Prod;
  }
  if (hostname.endsWith('stg.4dorian.net')) {
    return RuntimeEnv.Stg;
  }
  if (hostname.endsWith('dev.4dorian.net') || hostname.endsWith('rev.4dorian.net')) {
    return RuntimeEnv.Dev;
  }
  if (hostname.endsWith('localhost')) {
    return RuntimeEnv.Local;
  }

  // fallback
  return RuntimeEnv.Dev;
};

export const isProd = () => getRuntimeEnv() === RuntimeEnv.Prod;
export const isDev = () => getRuntimeEnv() === RuntimeEnv.Dev;
export const isLocal = () => getRuntimeEnv() === RuntimeEnv.Local;

export const isCTAsPreviewEnabled = () => true;
