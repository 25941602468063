import { getRuntimeEnv } from '../../helpers/runtimeEnv';
import { RuntimeEnv } from '../../helpers/RuntimeEnvEnum';
import { LoggerLevel } from './loggerService';

export const getLogLevelByEnv = (): number => {
  const logLevel = localStorage.getItem('logLevel');

  if (logLevel && Number(logLevel) >= 0) {
    return Number(logLevel);
  }

  const env = getRuntimeEnv();
  switch (env) {
    case RuntimeEnv.Prod:
      return LoggerLevel.Error;
    case RuntimeEnv.Stg:
      return LoggerLevel.Debug;
    case RuntimeEnv.Dev:
    case RuntimeEnv.Local:
      return LoggerLevel.Log;
    default:
      break;
  }
  return LoggerLevel.Silent;
};
